import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import "../components/scss/app.scss"

const AboutKalotiPage = () => (
    <Layout>
    <SEO title="ClearCorrect Invisible Braces | Teeth Aligners | Cosmetic Dentistry" 
    description="Want straight teeth without the hassle of metal braces? Find out how The Smile Stylist uses invisible braces to professionally and effectively straighten your smile." 
    keywords="ClearCorrect Invisible Braces, Teeth Aligners" />
    <main className="site-content" role="main">
    <section id="main-top-img" className="invisible-braces hidden-xs">
      <div className="container">
        <div className="row">
          <div className="col-sm-12 sec-title wow animated fadeInDown">
            <h1>Clear Correct</h1>
            <h2>Invisible Braces</h2>
          </div>
        </div>
      </div>
    </section>
    <section id="main-white">
      <div className="container">
        <div className="row">
          <div className="col-sm-8 wow animated fadeInRight">
            <p className="lead">Looking for an alternative to metal braces?</p>
            <h2>What about Invisible Braces?</h2>
            <p>
              <a href="https://www.straumann.com/clearcorrect/en/home.html" target="_blank">ClearCorrect</a> is the clear and simple choice. No wires. No brackets. Just clear, convenient comfort every reason to smile.
            </p>
            <p>With ClearCorrect, your dentist or orthodontist can straighten your teeth using a series of clear, custom, removable aligners. Each aligner moves your teeth just a little bit at a time until you eventually get straight teeth.</p>
            <h2>How does it work?</h2>
            <p>We will evaluate your teeth with you and talk about any problems or goals you have for your smile. Once we establish ClearCorrect is the right treatment option for you, we will take impressions, photos, and x-rays of your teeth that ClearCorrect uses in manufacturing your custom aligners. Then your prescription gets sent for your custom aligners to ClearCorrect.</p>
            <h2>What can it do?</h2>
            <p>ClearCorrect can treat a wide variety of issues that keep people from achieving their ideal smiles. Straighter teeth don't just look better; they work better too. Poorly-aligned teeth can interfere with bite function, wear out quicker, and are more prone to cavities. Ask us how ClearCorrect can help you, and see it first hand with our online <a href="/virtual-smile-makeover">Virtual Smile Makeover tool</a></p>

            <p><img src={ require("../images/desktop/services/clear-correct.jpg" )} alt="Invisible Braces" /></p>
            <p><a className="btn btn-red" href="/contact">Book Your Appointment</a></p>
          </div>
          <div className="col-sm-4 wow animated zoomIn text-center" data-wow-delay="0.4s">
            <p><img src={ require("../images/desktop/services/ClearAligners.jpg" )} alt="Clear Alligners " className="img-responsive" /></p>
          </div>
        </div>
      </div>
    </section>
  </main>
  </Layout>
)

export default AboutKalotiPage